import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Grid, Typography } from '@material-ui/core'
import Image from 'next/image'
import { Button } from '../button/button'
import { OrangeTriangleSvg, RedTriangleSvg, YellowTriangleSvg } from './element/TriangleSvg'
import { Rotator } from './element/Rotator'
import { getDimensions } from '../../utils/getDimensions'
import { LinkProps } from '../link/link'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { HomeBannerContext } from './HomeBanners'

export type ImageProps = { 
  src: string
  i: number
} & React.ComponentProps<typeof BannerContainer>

export type TitleProps = {
  text: string
} & React.ComponentProps<typeof StyledTitle> 

export type SubtitleProps = {
  text: React.ReactNode
} & React.ComponentProps<typeof StyledSubtitle> 

export type CtaProps = {
  href: string
  target?: string
} & LinkProps & React.ComponentProps<typeof StyledButton> 

export type HomeBannerProps = {image?: boolean; title?: boolean; subtitle?: boolean; cta?: boolean; href?: string; target?: string; src?: string; text?: string|React.ReactNode} & (
  | ({image:true} & ImageProps )
  | ({title:true} & TitleProps )
  | ({subtitle:true} & SubtitleProps )
  | ({cta:true} & CtaProps)
  |  React.ComponentProps<typeof BannerContainer>
)


const StyledTitle = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(30)};
  font-weight: 500;
  margin-top: ${props => props.theme.typography.pxToRem(4)};
  margin-bottom: 0;
  opacity: 1;
  line-clamp: 3;
  line-height: 1.1;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  max-width: ${props => props.theme.typography.pxToRem(426)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: 0;
    font-size: ${props => props.theme.typography.pxToRem(45)};
    font-weight: 500;
    max-width: ${props => props.theme.typography.pxToRem(426)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    width: 100%;
    text-align: left;
    max-width: ${props => props.theme.typography.pxToRem(340)};
  }

  ${props => props.theme.breakpoints.down('xs')} {
    width: 70%;
    text-align: center;
  }
`

const StyledSubtitle = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(17)};
  margin-top: ${props => props.theme.typography.pxToRem(40)};
  max-width: ${props => props.theme.typography.pxToRem(426)};
  line-clamp: 3;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  text-align: center;
  display: none;
  width: 70%;
  ${props => props.theme.breakpoints.up('sm')} {
    display: block;
    width: 90%;
    text-align: left;
    line-height: ${props => props.theme.typography.pxToRem(35)};
  }
`

const StyledButton =styled(Button)`
  font-size: ${props => props.theme.typography.pxToRem(16)};
  margin-top: ${props => props.theme.typography.pxToRem(40)};
  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(18)};
  }
`

const StyledClearFix = styled.div<{$height:number}>`
  height: ${props => props.theme.typography.pxToRem(props.$height)};
  ${props => props.theme.breakpoints.up('sm')} {
    height: ${props => props.theme.typography.pxToRem(props.$height*0.8)};
    margin-top: ${props => props.theme.typography.pxToRem(30)};
  }
  ${props => props.theme.breakpoints.up('md')} {
    height: ${props => props.theme.typography.pxToRem(props.$height*0.6)};
    margin-top: ${props => props.theme.typography.pxToRem(30)};
  }

  ${props => props.theme.breakpoints.up('lg')} {
    max-height: ${props => props.theme.typography.pxToRem(890)};
  }
`

export const ImageBox = styled.div`
  position: relative;
  overflow: hidden;
  margin: ${props => props.theme.typography.pxToRem(10)} ${props => props.theme.typography.pxToRem(10)} 0 ${props => props.theme.typography.pxToRem(10)};
  padding-bottom: ${props => props.theme.typography.pxToRem(30)};

  ${props => props.theme.breakpoints.up('sm')} {
    overflow: visible;
    position: absolute;
    margin: unset;
    top: ${props => props.theme.typography.pxToRem(60)};
    left: unset;
    right: ${props => props.theme.typography.pxToRem(40)};
    height: auto;
    max-width: ${props => props.theme.typography.pxToRem(420)};
    max-height: ${props => props.theme.typography.pxToRem(410)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    top: ${props => props.theme.typography.pxToRem(10)};
    width: ${props => props.theme.typography.pxToRem(680)};
    left: unset;
    right: ${props => props.theme.typography.pxToRem(80)};
    height: unset;
    max-width: ${props => props.theme.typography.pxToRem(991)};
    max-height: ${props => props.theme.typography.pxToRem(890)};
  }

  ${props => props.theme.breakpoints.up('lg')} {
    width: ${props => props.theme.typography.pxToRem(890)};
    left: ${props => props.theme.typography.pxToRem(860)};
    right: unset;
  }

  ${props => props.theme.breakpoints.up('xl')} {
    left: unset;
  }
`

const StyledContent =styled(Grid)`
  align-items: center;
  margin-top: 0;

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${props => props.theme.typography.pxToRem(20)};
    align-items: flex-start;
    width: ${props => props.theme.typography.pxToRem(485)};
    max-height: ${props => props.theme.typography.pxToRem(890)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => props.theme.typography.pxToRem(50)};
    padding-left: 0;
  }
`
const BannerContainer = styled(Grid)`
  display: flex;
  max-width: ${props => props.theme.typography.pxToRem(1650)};
  margin: auto;
`

export const bannerMinHeights = css`
  ${props => props.theme.breakpoints.up('sm')} {
    min-height: ${props => props.theme.typography.pxToRem(360)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    min-height: ${props => props.theme.typography.pxToRem(500)};
  }

  ${props => props.theme.breakpoints.up('lg')} {
    height: ${props => props.theme.typography.pxToRem(720)};
  }
`

const BannerImageContainer = styled(Grid)`
  display: inline-block;
  min-height: unset;

  ${bannerMinHeights};
`

export const StyledImage = styled(Rotator)`
  transition-duration: 0.4s;
  transition-property: transform;
  max-width: ${props => props.theme.typography.pxToRem(990)};

  ${props => props.theme.breakpoints.up('sm')} {
    height: ${props => props.theme.typography.pxToRem(400)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    width: ${props => props.theme.typography.pxToRem(680)};
    height: ${props => props.theme.typography.pxToRem(628)};
  }

  ${props => props.theme.breakpoints.up('lg')} {
    width: ${props => props.theme.typography.pxToRem(890)};
    height: ${props => props.theme.typography.pxToRem(830)};
  }
`

const StyledLargeTriangle = styled(Rotator)`
  position: absolute;
  transition-duration: 0.4s;
  transform: rotate(-5deg);
  left: 15%;
  width: 12%;
  top: 70%;

  ${props => props.theme.breakpoints.up('sm')} {
    top: 79%;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    top: 79%;
    width: 13%;
  }
`

const StyledMediumTriangle = styled(Rotator)`
  position: absolute;
  transition-duration: 0.4s;
  transform: rotate(-5deg);
  left: 85%;
  top: 55%;
  width: 8%;

  ${props => props.theme.breakpoints.up('sm')} {
    left: 83%;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    top: 70%;
    left: 86%;
  }

`

const StyledSmallTriangle = styled(Rotator)`
  position: absolute;
  transition-duration: 0.4s;
  transform: rotate(-1deg);
  left: 88%;
  width: 5%;
  top: 28%;

  ${props => props.theme.breakpoints.up('lg')} {
    top: 40%;
    width: 5%;
    left: 88%;
  }

`

export const useContainerDimensions = ref => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions(ref))
    }

    if (ref.current) {
      setDimensions(getDimensions(ref))
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ref])

  dimensions.height = dimensions.width * 890 / 990

  return dimensions
}

export const HomeBanner: React.FC<HomeBannerProps> = ({ image, title, subtitle, cta, href, target, src, text, ...props }) => {
  if(title){
    return ( // @ts-ignore
      <StyledTitle variant="h2" component={'h1'}>
        {text}
      </StyledTitle>      
    )
  }

  if(subtitle){
    return (
      <StyledSubtitle variant="body1">        
        {text}
      </StyledSubtitle>
    )
  }

  if(cta){
    return (
      <HomeBannerButton href={href} target={target}>{text}</HomeBannerButton>
    )
  }

  if(image && src){
    return (
      <HomeBannerImage { ...props as ImageProps } src={src} />
    )
  }

  return null
}

const HomeBannerButton: React.FC<Partial<CtaProps>> = ({ href, target, children }) => {
  if(href) {
    return (
      <StyledButton primary buttonColor="black" href={href} target={target}>
        {children}
      </StyledButton>
    )
  }

  return (
    <StyledButton primary buttonColor="black">
      {children}
    </StyledButton>
  )
}

export const HomeBannerImage: React.FC<ImageProps> = ({ priority, width, height, i, src, children, ...containerProps }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const { currentBanner } = useContext(HomeBannerContext)

  return width && height && currentBanner === i && (
    <BannerContainer {...containerProps} key={i} container direction="row-reverse" justifyContent="center">
      <BannerImageContainer container item justifyContent="center" alignItems="flex-start" xs={12} sm={7} md={7}>
        <ImageBox>
          {src && (
            <StyledImage rg={0} ypx={60} >
              <Image priority={priority} width={width} height={height} src={src} alt="" />
            </StyledImage>
          )}
          <StyledLargeTriangle rg={-1} ypx={60}><RedTriangleSvg/></StyledLargeTriangle>
          <StyledMediumTriangle rg={-5} ypx={40}><OrangeTriangleSvg/></StyledMediumTriangle>
          <StyledSmallTriangle rg={-1} ypx={40}><YellowTriangleSvg/></StyledSmallTriangle>
        </ImageBox>
        {isDesktop && <StyledClearFix $height={height} />}
      </BannerImageContainer>
      <StyledContent container direction="column" justifyContent="center"  alignItems="flex-start" item xs={12} sm={5} md={5} spacing={0}>
        {children}
      </StyledContent>
    </BannerContainer>
  )
}

export const StyledBannerWrapper = styled.div`
  ${bannerMinHeights};
`